body {
  background-image: url("assets/images/bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #1B2121 !important;
}

.preloader {
  height: 100vh;
  background-image: url("assets/images/bigLogo.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

html,
body,
div[id="root"] {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  font-family: Inter, Helvetica, sans-serif;
  ::-webkit-datetime-edit-fields-wrapper {
    position: absolute;
    left: 12px;
    top: 35%;
  }
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 12px;
    top: 33%;
    background-image: url('../src/assets/images/calendar.svg');
  }
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

*,
::after,
::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}


ul {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

li {
  list-style-type: none;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

button {
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.slide-enter-done {
  transform: translateX(0);
}

.Toastify__toast-container {
  padding: 0 !important;
  width: fit-content !important;
  height: fit-content !important;
  @media (max-width: 480px) {
    left: auto !important;
  }
}
.Toastify__toast-body {
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
  border-radius: 50% !important;
}
.Toastify__toast {
  padding: 0 !important;
  display: flex;
  box-shadow: none;
  color: transparent;
  width: 100%;
  overflow: visible;
  background: transparent !important;
  border-radius: 12px !important;

  & > svg {
    position: absolute;
    right: 16px;
    top: 20px;
  }

  &--success {
  }

  &--error {
    // background: $color-orange;
  }

  &--info {
  }
}
.Toastify__toast-theme--light {
  background: none;
}

.Toastify__close-button {
  align-self: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: red;
  background: blue;

  & > svg {
    display: none;
  }

  // custom cross
  &:after {
    content: '\e80a';
    font-family: Inter, Arial, Helvetica, sans-serif;
  }

}
